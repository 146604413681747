<template>
    <div>

        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            详情
        </div>
        <!-- 标题 end -->

        <!-- 头部固件详情 start -->
        <div class="firmware_msg">
            <div class="firmware_msg_list">
                <div class="firmware_msg_top">
                    <div class="firmware_name">固件信息</div>
                </div>
                <div class="firmware_msg_bottom">
                    <div class="firmware_msg_item"><span
                        class="firmware_msg_item_title">固件版本：</span>{{ firmwareData.firmwareVersion }}
                    </div>
                    <div class="firmware_msg_item"><span
                        class="firmware_msg_item_title">创建时间：</span>{{ firmwareData.createTime }}
                    </div>
                    <div class="firmware_msg_item"><span class="firmware_msg_item_title">升级方式：</span>{{
                            $config.getMsgItemUtil($message.upgradeTypeList, firmwareData.upgradeType, 'id', 'name')
                        }}
                    </div>
                    <div class="firmware_msg_item"><span class="firmware_msg_item_title">升级区域：</span>{{
                            $config.getMsgItemUtil($message.regionList, firmwareData.region, 'id', 'name')
                        }}
                    </div>
                    <div class="firmware_msg_item"><span
                        class="firmware_msg_item_title">更新日志：</span>{{ firmwareData.firmwareLog }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 头部固件详情 end -->

        <!-- 数据展示卡片 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '16px',
                height: cardHeight + 'px'
            }"
            shadow="never">

            <div slot="header" class="clearfix">
                <span>已升级用户概览</span>
            </div>

            <div id="leftChart" style="width: 50%;float: left;"></div>

            <div id="rightChart" style="width: 50%;float: left;"></div>

        </el-card>
        <!-- 数据展示卡片 end -->

    </div>
</template>

<script>
import {Pie, Column} from '@antv/g2plot';

export default {

    name: "firmwareMsg",

    data() {

        return {

            firmwareData: {
                //固件版本
                firmwareVersion: "nkjansfjnasjkf",
                //创建时间
                createTime: "2022-1-1  12:00",
                //升级方式
                upgradeType: 0,
                //升级区域
                region: -1,
                //更新日志
                firmwareLog: "修复BUG"
            },

            cardHeight: 300,

            leftData: [
                {type: '已升级', value: 151111},
                {type: '未升级', value: 37777},
            ],

            rightData: [
                {type: '北京', sales: 38},
                {type: '上海', sales: 52},
                {type: '广州', sales: 61},
                {type: '成都', sales: 145},
                {type: '重庆', sales: 48}
            ],

            leftChart: null,

            rightChart: null

        }

    },

    mounted() {
        setTimeout(() => {
            this.setHeight();
        }, 100);
        window.addEventListener('resize', this.setHeight);
        this.setLeftChart();
        this.setRightChart();
    },

    methods: {

        /**
         * 设备表格高度
         */
        setHeight() {
            this.cardHeight = window.innerHeight - 340;
            if (this.rightChart && this.rightChart) {
                this.leftChart.render();
                this.rightChart.render();
            }
        },

        setLeftChart() {
            this.leftChart = new Pie('leftChart', {
                appendPadding: 70,
                data: this.leftData,
                angleField: 'value',
                colorField: 'type',
                color: ['#1890FF', '#36CBCB'],
                radius: 1,
                innerRadius: 0.8,
                label: {
                    type: 'outer',
                    offset: '-50%',
                    content: '{value}',
                    labelLine: null,
                    style: {
                        fill: '#00000000',
                    },
                },
                legend: {
                    offsetX: -10,
                    title: {
                        text: '总人数 : 188888',
                        spacing: 10,
                        style: {
                            fontSize: 14,
                            fill: "rgba(0,0,0,0.85)"
                        }
                    },
                    itemValue: {
                        formatter: (text, item) => {
                            const items = this.leftData.filter((d) => d.type === item.value);
                            console.log(items)
                            return items.length ? `|\t\t${(items.reduce((a, b) => a + b.value, 0)/188888).toFixed(2)*100}%\t\t${items.reduce((a, b) => a + b.value, 0)}` : '-';
                        },
                        style: {
                            opacity: 0.65,
                        },
                    },
                },
                interactions: [{type: 'element-selected'}, {type: 'element-active'}],
                statistic: {
                    title: {
                        offsetY: -4,
                        customHtml: (container) => {
                            return `<div style="=font-size:14px;color:rgba(0,0,0,0.45);line-height:inherit;text-align: center;">${"总用户(人)"}</div>`;
                        },
                    },
                    content: {
                        style: {
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                        customHtml: (container, view, datum, data) => {
                            const text = datum ? ` ${datum.value}` : ` ${data.reduce((r, d) => r + d.value, 0)}`;
                            return `<div style="=font-size:30px;color: rgba(0,0,0,0.85);line-height:inherit;text-align: center;margin-top: 4px;">${text}</div>`;
                        },
                    },
                },
            });

            this.leftChart.render();
        },

        setRightChart() {
            this.rightChart = new Column('rightChart', {
                data: this.rightData,
                xField: 'type',
                yField: 'sales',
                color: '#1890FF',
                label: {
                    // 可手动配置 label 数据标签位置
                    position: 'top', // 'top', 'bottom', 'middle',
                    // 配置样式
                    style: {
                        fill: '#000000',
                        opacity: 0.6,
                    },
                    offsetY: 10
                },
                appendPadding: [10, 10, 0, 0],
                xAxis: {
                    label: {
                        autoHide: true,
                        autoRotate: false
                    },
                    title: {
                        text: "\t\t\t城市",
                        position: "end",
                        spacing: -25,
                        style: {
                            fill: "rgba(0,0,0,0.45)",
                            r: 100
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: "已升级用户数(人)\n\n\n",
                        position: "end",
                        rotation: -Math.PI / 2,
                        autoRotate: false,
                        spacing: -14,
                        style: {
                            fill: "rgba(0,0,0,0.45)"
                        }
                    }
                },
                meta: {
                    type: {
                        type: "cat",
                        alias: '城市',
                    },
                    sales: {
                        alias: '已升级用户数(人)',
                    },
                },
            });

            this.rightChart.render();
        }

    }

}
</script>

<style scoped>
.firmware_msg {
    height: 92px;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 24px 24px;
}

.firmware_msg_list {
    height: 72px;
    margin: 10px 20px;
    float: left;
    text-align: left;
}

.firmware_msg_top {
    height: 22px;
    padding: 7px 0;
}

.firmware_name {
    font-weight: bold;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    float: left;
    line-height: 22px;
}

.firmware_msg_bottom {
    height: 22px;
    margin-top: 10px;
}

.firmware_msg_item {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 40px;
}

.firmware_msg_item:first-child {
    margin-left: 0;
}

.firmware_msg_item_title {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
}

.el-card {
    border: none;
    border-radius: 8px;
    margin: 0 24px;
}

.clearfix {
    text-align: left;
}

/deep/ .el-card__header {
    padding: 10px 20px;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
}
</style>
